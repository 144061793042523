import React, { useEffect, useState } from "react";
import Amplify, { Auth, Hub } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Link,
} from "react-router-dom";

import { TransitionGroup, CSSTransition } from "react-transition-group";

import Landing from "./Landing";
import ChatView from "./ChatView";
import Cookies from "universal-cookie";
import DeepLinkCharacter from "./DeepLinkCharacter";
import DeepLinkUser from "./DeepLinkUser";
import { API } from "aws-amplify";

const cookies = new Cookies();

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

function AppContent(props) {
  let location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition
        key={useLocation().pathname}
        classNames="fade"
        timeout={3000}
      >
        <Routes location={useLocation()}>
          <Route path="/" element={<Landing />} />
          <Route path="/chat" element={<Landing />} />
          <Route path="/c/" element={<Landing />} />
          {/* also /c/:id */}
          <Route path="/c/:id" element={<DeepLinkCharacter />} />
          <Route path="/u/:id" element={<DeepLinkUser />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
    // <Landing></Landing>
  );
}

const App = () => {
  const [user, setUser] = useState(null);

  //access fake user id from child component
  const [fakeUserId, setFakeUserId] = useState(cookies.get("fakeUserId"));

  const convertFakeUserToRealUser = async (userData) => {
    console.log("converting fake user to real user");
    console.log(fakeUserId);
    console.log(userData);
    if (fakeUserId && userData && userData.attributes.sub) {
      const conversion = API.post(
        "famouscoachAPI",
        "/fakeUserRealUserConvert",
        {
          body: {
            fakeAccountId: fakeUserId,
            realUserId: userData.attributes.sub,
          },
        }
      );
      await conversion;
    }
  };

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log("hub event", event);
      console.log("hub data", data);
      switch (event) {
        case "signIn":
          console.log("user signed in");
          console.log("and heres the fake user id", fakeUserId);
          getUser().then((userData) => convertFakeUserToRealUser(userData));
          break;
        case "cognitoHostedUI":
          getUser().then((userData) => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });

    getUser().then((userData) => setUser(userData));
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }

  return (
    <Router>
      {/* <p>? User: {user ? JSON.stringify(user.attributes) : 'None'}</p> */}
      <AppContent setFakeUserIdP={setFakeUserId} user={user} />
    </Router>
  );
};

export default App;

// return (
// <div style={{margin:"auto", width:"50%" }}>
//   <p>? User: {user ? JSON.stringify(user.attributes) : 'None'}</p>
//   {user ? (
//     <button onClick={() => Auth.signOut()}>Sign Out</button>
//   ) : (
//     <button onClick={() => Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google})}>Sign in with Apple</button>
//   )}
// </div>
//   );
