import React from "react";
import FocusPic from "./assets/tesla.png";
import ConfidencePic from "./assets/shake.png";
import HappyPic from "./assets/Aristotle.png";
import CalmnessPic from "./assets/jobs.png";
import ResilientPic from "./assets/einstein.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
//import landing.css
// import './Landing.css';

// import { CSSTransition } from 'react-transition-group';
import _ from "lodash";
// import './App.css';

class Landing extends React.Component {
  state = {
    sloganPart1: "",
    sloganPart2: "",
    selectionMap: {
      FOCUSED: false,
      CONFIDENT: false,
      HAPPY: false,
      CALM: false,
      RESILIENT: false,
    },
    valid: false,
    submitted: false,
    receivedChallenges: false,
    challenges: null,
  };
  render() {
    return (
      <main
        style={{
          backgroundColor: "#171631",
        }}
      >
        <Helmet defer={true}>
          <title>Famed.Chat</title>
          {/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css" integrity="sha512-wnea99uKIC3TJF7v4eKk4Y+lMz2Mklv18+r4na2Gn1abDRPPOeef95xTzdwGD9e6zXJBteMIhZ1+68QC5byJZw==" crossorigin="anonymous" referrerpolicy="no-referrer" /> */}
          {/* <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script> */}
          <link rel="stylesheet" href="/bootstrap.min.css"></link>
          <link rel="stylesheet" href="/css/animate.min.css"></link>
          <link rel="stylesheet" href="/css/magnific-popup.css"></link>
          <link rel="stylesheet" href="/fontawesome/css/all.min.css"></link>
          <link rel="stylesheet" href="/css/dripicons.css"></link>
          <link rel="stylesheet" href="/css/slick.css"></link>
          <link rel="stylesheet" href="/css/default.css"></link>
          <link rel="stylesheet" href="/css/swiper.css"></link>
          <link rel="stylesheet" href="/css/responsive.css"></link>
          <link rel="stylesheet" href="/css/style.css"></link>
          <link rel="stylesheet" href="/css/bites-theme.css"></link>
          <link rel="stylesheet" href="./Landing.css"></link>
        </Helmet>
        <div
          class="alert alert-warning alert-dismissible fade show alert-cool"
          role="alert"
        >
          <strong>WARNING!</strong> This website is for entertainment purposes
          only. The transcripts generated by AI models are not real, and any
          resemblance to actual people or conversations is purely coincidental.
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <section
          className="slider-area slider-bg2 second-slider-bg d-flex fix"
          style={{
            backgroundPosition: "right 0",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            // backgroundColor: "#171631",
          }}
        >
          <div id="stars"></div>
          <div id="stars2"></div>
          <div id="stars3"></div>
          <div className="container action-space">
            <div className="row">
              <div className="col-lg-12">
                <div className="slider-content second-slider-content text-center">
                  <a href="/">
                    <h2
                      className="slogan"
                      data-animation="fadeInUp"
                      data-delay=".4s"
                    >
                      Famed.<span>Chat</span>
                    </h2>
                  </a>
                  <h3 className="subslogan">
                    text with your favorite AI characters 🤖⭐
                  </h3>
                  <h3
                    style={{
                      color: "#ff3494",
                      marginTop: "40px",
                    }}
                  >
                    download our app <br />
                    to chat now👇
                  </h3>
                </div>
              </div>
            </div>

            {/* <div className="results" style={ (this.state.submitted && !this.state.receivedChallenges) ? {visibility: "visible", opacity:1, transition:"opacity 2s"} : {visibility: "hidden", opacity:0, transition:"opacity 2s"}}>
            <Search></Search>
          </div> */}

            {/* <div className="results" style={this.state.receivedChallenges ? {opacity: 1} : {display: "none"}}>
            <Results challenges={this.state.challenges}></Results>
          </div> */}

            <div className="row mobile_coaches main-body-after">
              <div className="col-lg-12">
                <div className="slider-content text-center">
                  <a
                    class="news-app-promo-subsection--link news-app-promo-subsection--playstore justify-content-center text-center mt-20"
                    href="https://play.google.com/store/apps/details?id=com.ultrachat.ultrachat&hl=en_US"
                    target="_parent"
                  >
                    <img
                      class="news-app-promo__play-store mt-30"
                      src="//news.files.bbci.co.uk/include/newsspec/19854/assets/app-project-assets/google_play_store.svg"
                      width="80%"
                      height="auto"
                      border="0"
                    ></img>
                  </a>{" "}
                  <br></br>
                  <a
                    class="news-app-promo-subsection--link news-app-promo-subsection--appstore justify-content-center text-center mt-20"
                    href="https://apps.apple.com/us/app/famed-chat-ai-icon-chat/id6446922396"
                    target="_parent"
                  >
                    <img
                      class="news-app-promo__app-store mt-20"
                      src="//news.files.bbci.co.uk/include/newsspec/19854/assets/app-project-assets/ios_app_store.svg"
                      width="80%"
                      height="auto"
                      border="0"
                    ></img>
                  </a>
                </div>
              </div>
            </div>

            <div
              id="coaches"
              className="main-body-after coaches"
              style={
                this.state.receivedChallenges
                  ? { display: "none" }
                  : this.state.submitted
                  ? { visibility: "hidden", opacity: 0 }
                  : { visibility: "visible", opacity: 1 }
              }
            >
              <div className="row text-center">
                <div className="col-lg-12 text-center">
                  <a
                    class="text-center mt-20"
                    href="https://play.google.com/store/apps/details?id=com.ultrachat.ultrachat&hl=en_US"
                    target="_parent"
                  >
                    <img
                      class="mt-30"
                      src="//news.files.bbci.co.uk/include/newsspec/19854/assets/app-project-assets/google_play_store.svg"
                      style={{ maxWidth: "200px" }}
                      border="0"
                    ></img>
                  </a>
                  <a
                    class="text-center mt-20 ml-5"
                    href="https://apps.apple.com/us/app/famed-chat-ai-icon-chat/id6446922396"
                    target="_parent"
                  >
                    <img
                      class="mt-30"
                      src="//news.files.bbci.co.uk/include/newsspec/19854/assets/app-project-assets/ios_app_store.svg"
                      style={{ maxWidth: "200px" }}
                      border="0"
                    ></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default Landing;
