import React,  { useEffect, useState, useMemo } from "react";
// import "./ChatViewHelper.js";
import ResilientPic from './assets/einstein.png';
import CryPic from './assets/cry.png';
import PremiumPic from './assets/premium.png';
import TokenPic from './assets/token.png';
import BagPic from './assets/bag.png';
import ChestCoinPic from './assets/chest_coin.png';
import PaywallChat from './assets/paywall_chat.png';

import {Helmet} from "react-helmet"
// import './ChatView.css';
import { Dialog } from 'react-spring-dialog'
import Lottie from "lottie-react";
import Amplify, { Auth, Hub } from 'aws-amplify';
import groovyWalkAnimation from "./assets/einstein.json";
import crownAnimation from "./assets/crown.json";
import chestAnimation from './assets/chest.json';
import LoadingChat from "./assets/loading2.json";
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import Cookies from "universal-cookie";
// import 'tw-elements';
import { API } from 'aws-amplify';
// import awsConfig from './aws-exports';
import mixpanel from 'mixpanel-browser';
mixpanel.init('29e947ecfd874d8b50bda54ed6438e42');


const cookies = new Cookies();


function ChatView(props) {
  let user = props.user;
  const [fakeUser, setFakeUser] = useState(cookies.get('fakeUserId'));

  const [coaches, setCoaches] = useState([]);
  const [chatrooms, setChatrooms] = useState([]);
  const [profile, setProfile] = useState(null);
  const [currentChats, setCurrentChats] = useState([]);
  const [selectedChatroom, setSelectedChatroom] = useState(null);
  const [chatRoomLoading, setChatRoomLoading] = useState(true);
  const [submittingChat, setSubmittingChat] = useState(false);
  const [chatMessage, setChatMessage] = useState('');
  const [auth, setAuth] = useState(null);

  const createOrGetFakeAccountId = async () => { 
    if (fakeUser) {
      console.log("fake logged in");
      console.log(fakeUser);
    } else {
      try {
        const fakeAccount = API.post('famouscoachAPI', '/fakeAccount', {
            body: {
            }
          });
          let result = await fakeAccount;
          //Cookie expires after 100 years
          cookies.set('fakeUserId', result.id, { path: '/' 
            , expires: new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 365 * 100))});
          setFakeUser(result.id);
          mixpanel.track('Fake User Creation', {
            'fakeUserID': result.id,
          });
      } catch(err) {
          console.log(err);
          setIsErrored(true);
      }
    }
  }

  const submitChat = async () => {
    if (selectedChatroom && chatMessage) {
      let chatMessageS = chatMessage
      setChatMessage('');
      //last 3 recent chats
      console.log('TSR',currentChats);
      let recentChats = currentChats.slice(-6);
      let filteredStrings = ["Server error: Please try again later", "You don't have enough tokens. Buy some to continue chatting.", ""];
      // filter recent chats to remove any error messages
      recentChats = recentChats.filter(chat => !filteredStrings.includes(chat.message.S));
      //add to current chats
      let newCurrentChats = currentChats;
      newCurrentChats.push({
        message_type: {S: 'USER'},
        message: {S: chatMessageS},
        createdAt: new Date().toLocaleString(),
      });
      setCurrentChats(newCurrentChats);
      setSubmittingChat(true);
      try {
        let authHeaders = {}
        let auth = null;
        try {
          auth = await Auth.currentSession();
        } catch(err) {
          console.log(err);
        }
        if (auth){
          authHeaders = {
              Authorization: `Bearer ${auth.getAccessToken().getJwtToken()}`
          }
        }
        mixpanel.track('New Chat Sent', {
          'ChatMessage' : chatMessageS,
          'Sent To' : selectedChatroom.coach_name,
        });
        const chat = API.post('famouscoachAPI', '/submitFakeChat', {
            body: {
              fakeUserId: fakeUser,
              message: chatMessageS,
              messagesChatRoomId: selectedChatroom.id.S,
              recentChats: recentChats,
            },
            headers: authHeaders,
          });
          let result = await chat;
          let newCurrentChats = currentChats;
          newCurrentChats.push({
            message_type: {S: 'AI'},
            message: {S: result.aiMessage},
            createdAt: new Date().toLocaleString(),
          });
          mixpanel.track('AI Generated Message', {
            'Character' : selectedChatroom.coach_name,
            'response' : result.aiMessage,
          });
          setCurrentChats(newCurrentChats);
          //update profile with new tokens
          setProfile({...profile, tokens: {N: result.fakeUserTokens.N}});
          console.log ('SUBMITTED CHAT', result);
        } catch(err) {  
          console.log(err);
          setIsErrored(true);
        }
        setSubmittingChat(false);
      }
  }

  const getChats = async () => {
    if (selectedChatroom){
      try {
        const chats = await API.post('famouscoachAPI', '/getFakeChats', {
          body: {
            chatRoomId: selectedChatroom.id.S,
          }
        });
        //sort chats by created at
        let sortedChats = chats.sort((a, b) => {
          return new Date(a.createdAt.S) - new Date(b.createdAt.S);
        }
        );
        
        setCurrentChats(sortedChats);
      } catch(err) {
          console.log(err);
          setIsErrored(true);
      }
    }
  }

  const getFakeProfile = async () => {
    if (fakeUser) {
      try {
        let authHeaders = {}
        let auth = null;
        try {
          auth = await Auth.currentSession();
        } catch(err) {
          console.log(err);
        }
        if (auth){
          authHeaders = {
              Authorization: `Bearer ${auth.getAccessToken().getJwtToken()}`
          }
          mixpanel.track('Logged Back In', {
            'realuser': auth,
          });
        } else {
          mixpanel.track('Logged Back In', {
            'fakeUserID': fakeUser,
          });
        }
        console.log("AUTHHEADERS", authHeaders);
        const fakeProfile = API.post('famouscoachAPI', '/getFakeProfile', {
            body: {
              fakeAccountId: fakeUser
            },
            headers: authHeaders,
          });
          let result = await fakeProfile;
          console.log("get fake profile");
          console.log(result);
          setProfile(result);
      } catch(err) {
          console.log(err);
      }
    }
  }

  const getCoaches = async () => {
    try {
      const coaches = API.post('famouscoachAPI', '/getCoaches', {
        body: {}
      });
      let result = await coaches;
      console.log("get coaches");
      console.log(result);
      // put free coaches to the top of the array
      let freeCoaches = result.filter(coach => coach.coach_membership_type.S === 'FREE');
      let premiumCoaches = result.filter(coach => coach.coach_membership_type.S === 'PREMIUM');
      let sortedCoaches = freeCoaches.concat(premiumCoaches);
      setCoaches(sortedCoaches);
    } catch(err) {
        console.log(err);
        setIsErrored(true);
    }
  }

  const getChatrooms = async () => {
    try {
      if (fakeUser) {
        let authHeaders = {}
        let auth = null;
        try {
          auth = await Auth.currentSession();
        } catch(err) {
          console.log(err);
        }
        if (auth){
          authHeaders = {
              Authorization: `Bearer ${auth.getAccessToken().getJwtToken()}`
          }
        }
        const chatrooms = API.post('famouscoachAPI', '/getFakeChatrooms', {
          body: {
            fakeAccountId: fakeUser
          },
          headers: authHeaders,
        });
        let result = await chatrooms;
        console.log("get chatrooms");
        console.log(result);
        //sort results by date
        result.sort((a, b) => {
          return new Date(b.updatedAt.S) - new Date(a.updatedAt.S);
        });
        // remove chats with is_mobile_only = true
        // let filteredChatrooms = result.filter(chatroom => chatroom.is_mobile_only === false);
        setChatrooms(result);
        if (result[0]){
          selectChatroom(result[0]);
        }
      }
    } catch(err) {
        console.log(err);
    }
  }

  const selectChatroom = (chatroom) => {
    // const coach = coaches.find(coach => coach.id.S === chatroom.chatRoomCoachesId.S);
    setSelectedChatroom(chatroom);
  }

  useEffect(() => {
    async function setup (){
    if (!fakeUser) {
      console.log('fake user creation');
      let fk_pr = await createOrGetFakeAccountId();
      console.log(fk_pr);
    }
    let pr2 = getFakeProfile();
    let pr1 = getCoaches();
    await Promise.all([pr1, pr2]);
    let pr3 = getChatrooms();
    await pr3;
    try {
      let au = await Auth.currentSession();
      setAuth(au);
    } catch(err) {
      console.log(err);
    }
    setIsLoaded(true);
    }
    setup();  
  }, [user]);

  useEffect(() => {
    let body = document.getElementById("messageBody");
    if (body) {
      body.scrollTop = body.scrollHeight;
    }
  });

  useEffect(() => {
    console.log('WOWZA_1', props.user);
    if (!user){
    getFakeProfile();
    getChatrooms();
    }
  } , [fakeUser]);

  useEffect(() => {
    async function loadChats (){
      setChatRoomLoading(true);
      await getChats();
      setChatRoomLoading(false);
    }
    loadChats();
  } , [selectedChatroom]);
  
  const [isLoginPopupActive, setLoginPopupActive] = useState(false);
  const [isPaywallActive, setPaywallActive] = useState(false);
  const [isTokenPaywallActive, setTokenPaywallActive] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const [paywallYearly, setPaywallYearly] = useState(true);

  const handlePaywallYearly = (chkValue) => {
    setPaywallYearly(chkValue.target.checked);
  }



  //After 3 seconds, the modal will appear
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsActive(true);
  //   }, 1000);
  // }
  // , []);

  //Click handler
  const handleClick = () => {
    setLoginPopupActive(true);
  }

  const onAccountClick = () => {
    console.log('user');
    console.log(profile);
    if (!user) {
      setLoginPopupActive(true);
    } else {
      mixpanel.track('Web Account Page clicked', {
        'profile': profile
      });
      if (!(profile.membership_type.S == 'PREMIUM')) {
        setPaywallActive(true);
      } else {
        //open facebook on new tab
        window.open('https://billing.stripe.com/p/login/28og0z12ZfpKf0AfYY', '_blank');
      }
    }
  }

  const onTokenClick = () => {
    if (!user) {
      setLoginPopupActive(true);
    } else {
      mixpanel.track('Web Token Page clicked', {
        'user': user
      });
      setTokenPaywallActive(true);
    }
  }

  //In app spinning bars


  return (
    <div className="h-full" style={{display: "flex", flexDirection:"column", animation:"fadeIn 3s"}}>
      <Helmet defer={true}>
        <title>Famed.Chat</title>
        {/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css" integrity="sha512-wnea99uKIC3TJF7v4eKk4Y+lMz2Mklv18+r4na2Gn1abDRPPOeef95xTzdwGD9e6zXJBteMIhZ1+68QC5byJZw==" crossorigin="anonymous" referrerpolicy="no-referrer" /> */}
        {/* <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script> */}
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap" />
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/tw-elements/dist/css/index.min.css" />
        <script src="https://cdn.tailwindcss.com/3.1.5"></script>
        <script src="https://cdn.jsdelivr.net/npm/tw-elements/dist/js/index.min.js" type="text/javascript"></script>
        <link rel="stylesheet" href="https://unpkg.com/flowbite@1.5.3/dist/flowbite.min.css" />
        <script src="https://unpkg.com/flowbite@1.5.3/dist/flowbite.js"></script>
      </Helmet>
    { isLoaded ? (
    <div className="ChatView items-center justify-center">
       <Dialog isActive={isLoginPopupActive} onClose={() => setLoginPopupActive(false)}>
      <div><div className="font-sans flex-col max-w-xl bg-white items-center justify-center p-24 rounded-lg alert-cool lgn-box">
      <Lottie animationData={groovyWalkAnimation} loop={true} style={{ height: 200, marginBottom: '40px' }} />
  <div className="items-center justify-center">
    <h1 className="w-full text-white font-bold text-center text-xl">Sign in to access more characters <br></br> and get +<img src={TokenPic} className="inline w-6 h-6 cursor-pointer" style={{ maxHeight: '18px', maxWidth: '18px'}}></img>500 tokens!</h1>
    <div className="w-full space-y-7 text-center items-center justify-center mt-12">
      <button className="bg-black text-center text-white font-semibold px-4 py-3 rounded-md" style={{textAlign:'center !important', marginRight:'0px'}} onClick={() =>{
        Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Apple})
        }}>
        <svg style={{display: 'inline-block', marginTop: '-4px', marginRight: '20px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-apple" viewBox="0 0 16 16">
          <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z"/>
          <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z"/>
        </svg>
      Sign up with Apple
      </button>
      <button className="bg-red-500 text-center text-white font-semibold px-4 py-3 rounded-md" onClick={async () => {
        //update client metadata using amplify configur
        Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google})
        }}>
      <span>
      <svg style={{display: 'inline-block', marginTop: '-4px', marginRight: '20px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-google" viewBox="0 0 16 16">
  <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
</svg>
Sign up with Google
</span>
      </button>
    </div>
  </div>
</div></div>
    </Dialog>
    { (isLoaded && profile && profile.name) ? (
    <Dialog isActive={isPaywallActive} onClose={() => setPaywallActive(false)}>
      <div><div className="font-sans flex-col max-w-xl bg-white items-center justify-center pb-24 px-40 rounded-lg alert-paywall lgn-box" style={{minWidth:'700px'}}>
      <Lottie animationData={crownAnimation} loop={true} style={{ height: 150, marginBottom: '10px', marginTop: '30px' }} />
  <div className="items-center justify-center">

  <h1 className="w-full text-white font-bold text-center text-xl">Hello, {profile.name.S}</h1>
    <div className="w-full px-4 py-3 flex-1 text-center text-xl font-bold">
        <span style={{
          color: '#FFBB32',
        }}>Get Access to Premium Characters</span>
        {/* <span>{ profile ? profile.tokens.N : '-' } tkns</span> */}
      </div>

  <div class="p-4 w-full max-w-sm rounded-lg">
  <div class="flex justify-center">
  <label for="default-toggle" class="inline-flex relative items-center cursor-pointer mb-4 text-white">
  <input type="checkbox" checked={paywallYearly} onChange={(event) => setPaywallYearly(!paywallYearly)} id="default-toggle" class="sr-only peer"></input>
  <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-pink-500"></div>
  <span class="ml-3 text-sm font-medium text-white dark:text-gray-300">Yearly (20% OFF)</span>
</label>
</div>
        <div class="flex items-baseline text-gray-900 dark:text-black justify-center">
            <span class="text-3xl font-semibold text-white">$</span>
            <span class="text-5xl font-extrabold tracking-tight text-white">
              {paywallYearly ? '49.99' : '4.99'}
            </span>
            <span class="ml-1 text-xl font-normal text-white">{paywallYearly ? '/year' : '/month'}</span>
        </div>
        <ul role="list" class="my-7 space-y-5">
            <li class="flex space-x-3 justify-center">
            <img className="w-11 h-11 rounded-full" style={{height: '30px', width: '30px', display: 'inline', marginRight: '10px'}} id="premiumPic" src={PaywallChat}></img>
                <span class="text-base font-normal leading-tight text-white">Text with  <span class="font-extrabold" style={{
          color: '#FE3692',
        }}>hundreds</span> of different celebs, and historical icons</span>
            </li>
            <li class="flex space-x-3 justify-center">
            <img className="w-11 h-11 rounded-full" style={{height: '30px', width: '30px', display: 'inline', marginRight: '10px'}} id="premiumPic" src={TokenPic}></img>
                <span class="text-base font-normal leading-tight text-white"><span class="font-extrabold" style={{
          color: '#FE3692',
        }}>{paywallYearly ? '1,200,000 tk/yr' : '100,000 tk/mo'}</span> to ensure you can generously chat as you want</span>
            </li>
            <li class="flex space-x-3 justify-center">
            <img className="w-11 h-11 rounded-full" style={{height: '30px', width: '30px', display: 'inline', marginRight: '10px'}} id="premiumPic" src={PremiumPic}></img>
                <span class="text-base font-normal leading-tight text-white">Premium characters such as <span class="font-extrabold" style={{
          color: '#FE3692',
        }}>Einstein, Steve Jobs, Ronaldo</span></span>
            </li>
        </ul>
    </div>
    <div className="w-full text-center items-center justify-center">
      <button className="text-center text-white font-semibold px-10 py-3 rounded-md alert-cool" onClick={
        () => {
          // open new tab
          // console.log('userpaywall', user.attributes.sub)
          if (paywallYearly) {
            window.open('https://buy.stripe.com/00g9E48Ye9S174c001?prefilled_email='+user.attributes.email+'&client_reference_id='+user.attributes.sub, '_blank');
          } else {
            window.open('https://buy.stripe.com/cN2g2s4HY8NXewE8ww?prefilled_email='+user.attributes.email+'&client_reference_id='+user.attributes.sub, '_blank');
          }
      }
      }>
      <span>
      <img className="w-11 h-11 rounded-full" style={{height: '30px', width: '30px', display: 'inline', marginRight: '20px'}} id="premiumPic" src={PremiumPic}></img>
Go Premium
</span>
      </button>
    </div>
  </div>
</div></div>
    </Dialog>)
    : null }
    { (isLoaded && profile && profile.name) ? (
    <Dialog isActive={isTokenPaywallActive} onClose={() => setTokenPaywallActive(false)}>
      <div><div className="font-sans flex-col max-w-xl bg-white items-center justify-center pb-24 px-40 rounded-lg alert-paywall lgn-box" style={{minWidth:'700px'}}>
      <Lottie animationData={chestAnimation} loop={true} style={{ height: 150, marginBottom: '20px', marginTop: '30px' }} />
  <div className="items-center justify-center">

  <h1 className="w-full text-white font-bold text-center text-xl">Hello, {profile.name.S}</h1>
    <div className="w-full px-4 py-3 flex-1 text-center text-xl font-bold">
        <span style={{
          color: '#FFBB32',
        }}>Buy tokens to chat even more!</span>
        {/* <span>{ profile ? profile.tokens.N : '-' } tkns</span> */}
      </div>

  <div class="p-4 w-full max-w-sm rounded-lg">
  <div class="flex justify-center">
</div>
    </div>
    <div className="w-full text-center items-center justify-center">
      <button className="text-center text-white font-semibold px-10 py-3 rounded-md alert-cool" style={{minWidth: '340px'}} onClick={
        () => {
          // open new tab
          window.open('https://buy.stripe.com/bIY17y6Q6c094W4fZ1?prefilled_email='+user.attributes.email+'&client_reference_id='+user.attributes.sub, '_blank');
        }
      }>
      <span>
      <img className="w-11 h-11" style={{height: '30px', width: '30px', display: 'inline', marginRight: '20px'}} id="premiumPic" src={TokenPic}></img>
Buy 150k tokens for $4.99
</span>
      </button>
      <button style={{minWidth: '340px'}} className="text-center text-white font-semibold px-10 py-3 mt-3 rounded-md alert-cool" onClick={
        () => {
          // open new tab
          window.open('https://buy.stripe.com/dR64jK6Q6e8h3S05ko?prefilled_email='+user.attributes.email+'&client_reference_id='+user.attributes.sub, '_blank');
      }
      }>
      <span>
      <img className="w-11 h-11" style={{height: '30px', width: '30px', display: 'inline', marginRight: '20px'}} id="premiumPic" src={BagPic}></img>
Buy 500K tokens for $11.99
</span>
      </button>
      <button style={{minWidth: '340px'}} className="text-center text-white font-semibold px-10 py-3 mt-3 rounded-md alert-cool" onClick={
        () => {
          // open new tab
          window.open('https://buy.stripe.com/cN29E4eiy4xHbks6ot?prefilled_email='+user.attributes.email+'&client_reference_id='+user.attributes.sub, '_blank');
      }
      }>
      <span>
      <img className="w-11 h-11" style={{height: '30px', width: '30px', display: 'inline', marginRight: '20px'}} id="premiumPic" src={ChestCoinPic}></img>
Buy 1M tokens for $19.99
</span>
      </button>
    </div>
  </div>
</div></div>
    </Dialog>)
    : null }
      <template id="messageBlock">
</template>
<div className="w-full h-screen flex border-t-8 bg-black green-border">
  <aside className="overflow-y-auto border-r border-gray-800 relative block" style={{ backgroundColor: '#171531' }}>
    <div className="aside-header sticky top-0 right-0 left-0 z-40 text-gray-400">
      <div className="flex items-center px-4 py-3">
        <button className="flex-1 text-right" onClick={() => onTokenClick()}>
        <img src={TokenPic} className="inline w-6 h-6 cursor-pointer mr-2" style={{ maxHeight: '18px', maxWidth: '18px'}}></img>
        <span>{ profile ? profile.tokens.N : '-' } tkns</span>
        </button>
      </div>
    </div>

    <div className="accordion">
  <div className="accordion-item">
    <h2 className="accordion-header mb-0 alert-cool" id="headingOne5">
      <button className="
        accordion-button
        relative
        flex
        items-center
        w-full
        text-base text-gray-100 text-left
        border-0
        rounded-none
        transition
        focus:outline-none
        chat-btn
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne5" aria-expanded="true"
        aria-controls="collapseOne5"
        style={{ backgroundColor: 'transparent', color: 'white' }}
        >
        Active Chats
      </button>
    </h2>
    <div id="collapseOne5" className="accordion-collapse" aria-labelledby="headingOne5">
      <div className="accordion-body">
      <div id="mainMessages" className="aside-messages">

{
  chatrooms.length > 0 ? chatrooms.map((chatroom, index) => {
    //find the coach based on the chatRoomCoachesId
    const coach = coaches.find(coach => coach.id.S === chatroom.chatRoomCoachesId.S);
    console.log(selectedChatroom);
    console.log(coach);
    return (
      <div>
        {/* className={`${activeYourCharacter.id==character.id ? "active-celeb" : "message"} text-gray-300 px-4 py-3 border-b border-gray-700`} */}
  <button onClick={() => {selectChatroom(chatroom)}} className={`${selectedChatroom.chatRoomCoachesId.S==coach.id.S ? "active-celeb" : "active-celeb-2"} text-gray-300 px-4 py-3 border-b border-gray-700`} style={{width: '100%'}}>
<div className="flex items-center relative">
<div className="w-1/6">
  <img className="w-11 h-11 rounded-full" id="personHeadshot" src={coach.image_url.S}></img>
</div>
<div className="w-5/6" style={{textAlign: 'left'}}>
  <div className="text-xl text-white" id="personName">{coach.name.S}</div>
  <div className="text-sm truncate" id="messagePreview">{coach.description.S}</div>
</div>
</div>
</button>
  </div>
    )
  }
  ) : 
  (<div className="flex justify-center" style={{marginTop: '20px', marginBottom: '20px'}}>
  <div className="items-center justify-center" style={{textAlign: 'center'}}>
  <img style={{height: '100px', width: '100px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} src={CryPic}></img>
  <p className="text-lg mt-2 font-bold text-white">No chats found</p>
  <p className="text-lg mt-2 font-bold text-white">Start a chat now 👇</p>
  </div>
</div>)
}

</div>
      </div>
    </div>
  </div>
</div>

{/* <Context List  */}
<div className="accordion">
  <div className="accordion-item">
    <h2 className="accordion-header mb-0 alert-cool" id="contactList">
      <button className="
        accordion-button
        relative
        flex
        items-center
        w-full
        text-base text-gray-100 text-left
        border-0
        rounded-none
        transition
        focus:outline-none
        chat-btn
      " type="button" data-bs-toggle="collapse" data-bs-target="#contactListcollapse" aria-expanded="true"
        aria-controls="contactListcollapse"
        style={{ backgroundColor: 'transparent', color: 'white' }}
        >
        Contact List
      </button>
    </h2>
    <div id="contactListcollapse" className="accordion-collapse" aria-labelledby="contactList">
      <div className="accordion-body">
      <div id="mainMessages" className="aside-messages">

{
  coaches.map((character, index) => {
    // if (character.is_mobile_only.BOOL === true) {
    //   return ( <div>  </div> )
    // }

    if (character.coach_membership_type.S == 'PREMIUM') {
      return (
        <div>
  <div className={`${'FREE'==character.coach_membership_type.S ? "premium-celeb" : "message"} text-gray-300 px-4 py-3 border-b border-gray-700`}>
<div className="flex items-center relative" onClick={() => {
  if (!(profile.membership_type.S == 'PREMIUM')) {
    onAccountClick();
  }
  }} style={{cursor: 'pointer'}}>
<div className="w-1/6">
  <img className="w-11 h-11 rounded-full" id="personHeadshot" src={character.mobile_image_url.S}></img>
</div>
<div className="w-3/6">
  <div className="text-xl text-white" id="personName">{character.name.S}</div>
  <div className="text-sm truncate" id="messagePreview">{character.description.S}</div>
</div>
{ (character.coach_membership_type.S == 'PREMIUM' && !(profile && profile.membership_type && profile.membership_type.S == 'PREMIUM')) ?
<div className="w-2/6 items-center justify-center">
<img className="w-11 h-11 rounded-full" style={{height: '35px', width: '35px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} id="premiumPic" src={PremiumPic}></img>
<h3 className="text-sm text-white" style={{textAlign: 'center'}}>Premium</h3>
</div>
: <div className="w-2/6 items-center justify-center" style={{textAlign: 'center'}}>
  <ChatButton character={character} fakeUser={fakeUser} chatrooms={chatrooms} getChatrooms={getChatrooms} user={user}>
  </ChatButton>
</div> }
</div>
</div>
  </div>
      )
    }

    return (
      <div>
  <div className={`${'FREE'==character.coach_membership_type.S ? "premium-celeb" : "message"} text-gray-300 px-4 py-3 border-b border-gray-700`}>
<div className="flex items-center relative">
<div className="w-1/6">
  <img className="w-11 h-11 rounded-full" id="personHeadshot" src={character.image_url.S}></img>
</div>
<div className="w-3/6">
  <div className="text-xl text-white" id="personName">{character.name.S}</div>
  <div className="text-sm truncate" id="messagePreview">{character.description.S}</div>
</div>
{ (character.coach_membership_type.S == 'PREMIUM' && !(profile && profile.membership_type && profile.membership_type.S && profile.membership_type.S == 'PREMIUM')) ?
<div className="w-2/6 items-center justify-center">
<img className="w-11 h-11 rounded-full" style={{height: '35px', width: '35px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} id="premiumPic" src={PremiumPic}></img>
<h3 className="text-sm text-white" style={{textAlign: 'center'}}>Premium</h3>
</div>
: <div className="w-2/6 items-center justify-center" style={{textAlign: 'center'}}>
  <ChatButton character={character} fakeUser={fakeUser} chatrooms={chatrooms} getChatrooms={getChatrooms} user={user}>
  </ChatButton>
</div> }
</div>
</div>
  </div>
    )
  }
  )
}

</div>
      </div>
    </div>
  </div>
</div>


  </aside>
  <main id="messageBody" className="w-full bg-whatsapp relative overflow-y-auto">
    <div className="main-header z-40 sticky top-0 right-0 left-0 text-gray-400">
      <div className="flex items-center px-4 py-3">
        <div className="flex-1">
          <div className="flex">
            <div className="mr-4">
              { selectedChatroom  ? <img className="w-11 h-11 rounded-full" src={selectedChatroom.coach_pic}></img> : null }
            </div>
            <div>
              { selectedChatroom ? <p className="text-lg mt-2 font-bold text-white">{selectedChatroom.coach_name}</p> : null }
            </div>
          </div>
        </div>
        <button 
        onClick={() => onAccountClick()}
        className="flex-1 text-right">
          <span 
            style={{ marginRight: '15px' }}>
            { user ? user.attributes.name : "Sign In" }</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-circle inline w-6 h-6 cursor-pointer" viewBox="0 0 16 16">
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
          <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
        </svg>
        </button>
      </div>
    </div>
    <div className={`${chatRoomLoading ? "flex main-messages-loading block px-4 py-3" : "flex main-messages block px-4 py-3 align-bottom"}`}>
      { //go through all the chat messages and display them
        (currentChats && !chatRoomLoading) ? 
        currentChats.map((chat, index) => {
          console.log('CHATS', currentChats);
          if (chat.message_type.S === 'AI') {
          return (
            <div className="w-full justify-start">
            <div className="flex mm-child" style={{maxWidth:"80%", textAlign: 'left'}}>
              <span><svg className="incoming-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="8" height="13">
                  <path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path>
                  <path fill="currentColor" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path>
                </svg></span>
              <div className="single-message rounded-tr-lg text-gray-200 rounded-bl-lg rounded-br-lg alert-cool mb-4 px-4 py-2" style={{textAlign: 'left'}}>{chat.message.S}</div>
            </div>
            </div>
          );
          } else {
            return  (
              <div>
      <div className="flex justify-end mm-child">
        <div className="single-message rounded-tl-lg rounded-bl-lg text-gray-200 rounded-br-lg user mb-4 px-4 py-2">{chat.message.S}</div>
        <span><svg className="user-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="8" height="13">
            <path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path>
            <path fill="currentColor" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path>
          </svg></span>
      </div>
      </div>
            );
        }
    }
      ) : chatRoomLoading ? 
      <div className="w-full justify-start" style={{textAlign: 'center'}}>
        <div className="spinner-border text-fuchsia-500" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      : null 
      }
      {
        (currentChats && !chatRoomLoading && submittingChat) ? <div className="w-full justify-start">
            <div className="flex mm-child">
            <Lottie animationData={LoadingChat} loop={true} style={{ height: 50, marginBottom: '40px' }} />
            </div>
            </div>
      : null
      }
      </div>
      {/* <div className="w-full justify-start">
      <div className="flex mm-child">
        <span><svg className="incoming-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="8" height="13">
            <path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path>
            <path fill="currentColor" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path>
          </svg></span>
        <div className="single-message rounded-tr-lg text-gray-200 rounded-bl-lg rounded-br-lg alert-cool mb-4 px-4 py-2">Hey Pal! I'm doing good, how have you been? Cold at the moment aye!</div>
      </div>
      </div>

      <div>
      <div className="flex justify-end mm-child">
        <div className="single-message rounded-tl-lg rounded-bl-lg text-gray-200 rounded-br-lg user mb-4 px-4 py-2">Hey! Thought I'd reach out to say how are you? 😊</div>
        <span><svg className="user-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="8" height="13">
            <path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path>
            <path fill="currentColor" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path>
          </svg></span>
      </div>
      </div> */}


      {/* <div className="flex justify-end">
        <div className="single-message rounded-tl-lg rounded-bl-lg text-gray-200 rounded-br-lg user mb-4 px-4 py-2">Hey! Thought I'd reach out to say how are you? 😊</div>
        <span><svg className="user-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="8" height="13">
            <path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path>
            <path fill="currentColor" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path>
          </svg></span>
      </div>
      <div className="flex">
        <span><svg className="incoming-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="8" height="13">
            <path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path>
            <path fill="currentColor" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path>
          </svg></span>
        <div className="single-message rounded-tr-lg text-gray-200 rounded-bl-lg rounded-br-lg alert-cool mb-4 px-4 py-2">Hey Pal! I'm doing good, how have you been? Cold at the moment aye!</div>
      </div>
      <div className="flex justify-end">
        <div className="single-message rounded-tl-lg rounded-bl-lg text-gray-200 rounded-br-lg user mb-4 px-4 py-2">Hey! Thought I'd reach out to say how are you? 😊</div>
        <span><svg className="user-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="8" height="13">
            <path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path>
            <path fill="currentColor" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path>
          </svg></span>
      </div>
      <div className="flex">
        <span><svg className="incoming-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="8" height="13">
            <path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path>
            <path fill="currentColor" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path>
          </svg></span>
        <div className="single-message rounded-tr-lg text-gray-200 rounded-bl-lg rounded-br-lg alert-cool mb-4 px-4 py-2">Hey Pal! I'm doing good, how have you been? Cold at the moment aye!</div>
      </div>
      <div className="flex justify-end">
        <div className="single-message rounded-tl-lg rounded-bl-lg text-gray-200 rounded-br-lg user mb-4 px-4 py-2">Hey! Thought I'd reach out to say how are you? 😊</div>
        <span><svg className="user-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="8" height="13">
            <path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path>
            <path fill="currentColor" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path>
          </svg></span>
      </div>
      
      <div className="flex">
        <span><svg className="incoming-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="8" height="13">
            <path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path>
            <path fill="currentColor" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path>
          </svg></span>
        <div className="single-message rounded-tr-lg text-gray-200 rounded-bl-lg rounded-br-lg alert-cool mb-4 px-4 py-2">Hey Pal! I'm doing good, how have you been? Cold at the moment aye!</div>
      </div>
      <div className="flex justify-end">
        <div className="single-message rounded-tl-lg rounded-bl-lg text-gray-200 rounded-br-lg user mb-4 px-4 py-2">Hey! Thought I'd reach out to say how are you? 😊</div>
        <span><svg className="user-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="8" height="13">
            <path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path>
            <path fill="currentColor" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path>
          </svg></span>
      </div>
      <div className="flex">
        <span><svg className="incoming-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="8" height="13">
            <path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path>
            <path fill="currentColor" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path>
          </svg></span>
        <div className="single-message rounded-tr-lg text-gray-200 rounded-bl-lg rounded-br-lg alert-cool mb-4 px-4 py-2">Hey Pal! I'm doing good, how have you been? Cold at the moment aye!</div>
      </div>
      <div className="flex justify-end">
        <div className="single-message rounded-tl-lg rounded-bl-lg text-gray-200 rounded-br-lg user mb-4 px-4 py-2">Hey! Thought I'd reach out to say how are you? 😊</div>
        <span><svg className="user-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="8" height="13">
            <path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path>
            <path fill="currentColor" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path>
          </svg></span>
      </div>
      <div className="flex">
        <span><svg className="incoming-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="8" height="13">
            <path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path>
            <path fill="currentColor" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path>
          </svg></span>
        <div className="single-message rounded-tr-lg text-gray-200 rounded-bl-lg rounded-br-lg alert-cool mb-4 px-4 py-2">{user ? JSON.stringify(user.attributes) : 'None'}</div>
      </div> */}
    <div className="main-footer sticky bottom-0 right-0 left-0 text-gray-400">
      <div className="flex items-center px-1 py-1">
        <div className="flex-grow">
          <div className="px-4 py-2 w-full">
              <div className="relative text-gray-600 focus-within:text-gray-200">
                <input disabled={selectedChatroom ? false : true}
                value={chatMessage}
                maxLength="100"
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    submitChat();
                  }
                }}
                onChange={e => setChatMessage(e.target.value)} name="q" className="message-input w-full py-3 text-sm text-white bg-gray-700 rounded-full pl-5 focus:outline-none focus:text-gray-900" placeholder="Type a message" autoComplete="off"></input>
              </div>
          </div>
        </div>
        <button 
        disabled={(selectedChatroom && !submittingChat) ? false : true}
        onClick={() => submitChat()}
        className="flex-none text-right">
        <svg className="inline w-7 h-7 -mt-1 mr-4" xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 16 16">
          <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
        </svg>
        </button>
      </div>
    </div>
  </main>
  </div>
    </div>
    ) : (
      //Fullscreen with spinning wheel in the middle
      <div className="ChatView w-full h-screen flex justify-center items-center">  
        <div className="spinner-border text-fuchsia-500" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )}
    </div>
  );
}

function ChatButton(props) {
  console.log("PROPS CHAT BUTTON", props);
  console.log('hey', props.chatrooms.find(chatroom => chatroom.chatRoomCoachesId.S === props.character.id.S));
  const [isLoading, setIsLoading] = useState(false);
  const [isAdded, setIsAdded] = useState(
    //search props.chatrooms for an existing chatroom
    props.chatrooms.find(chatroom => chatroom.chatRoomCoachesId.S === props.character.id.S)
  );

  let onClick = async () => {
    setIsLoading(true);
    let ch = await createFakeChatroom();
    setIsLoading(false);
  }

  const createFakeChatroom = async () => { 
    if (props.fakeUser) {
      try {
        //api post with auth headers
        let authHeaders = {}
        if (props.user){
          authHeaders = {
              Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          }
        }
        console.log("AUTHHEADERS", authHeaders);
        mixpanel.track('New Chat Room', {
          'CoachID': props.character.id.S,
          'CoachName': props.character.name.S,
        });
        const fakeChatroom = API.post('famouscoachAPI', '/createFakeChatroom', {
            headers: authHeaders,
            body: {
              fakeAccountId: props.fakeUser,
              coachId: props.character.id.S
            }
          });
          let result = await fakeChatroom;
          await props.getChatrooms();
          setIsAdded(true);
      } catch(err) {
          console.log(err);
      }
    }
  }

  return (
    <div>
    {isLoading ? <div className="spinner-border text-fuchsia-500" role="status">
          <span className="sr-only">Loading...</span>
        </div> : isAdded ?
        <div>
          <svg style={{height: '35px', width: '35px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#5DBA7C" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
          </svg>
          <h3 className="text-sm text-white" style={{textAlign: 'center'}}>Added</h3>
        </div> :
        <button className="rounded-full alert-chat" onClick={onClick}>Chat</button>}
    </div>
  );
}


export default ChatView;
