// DeepLinkPage.js

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./Landing.css"; // Assuming that this is where all your styles are defined

const DeepLinkPage = () => {
  const { id } = useParams(); // Assuming your route is defined as '/c/:id'

  //isLoading
  const [isLoading, setIsLoading] = useState(false);

  const openCustomUrl = () => {
    //set loading
    setIsLoading(true);
    const deepLinkUrl = `ultrachat:///user?id=${id}`;

    // const customUrl = "ultrachat:///character?id=27282863";
    const isMobileDevice =
      /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    if (isMobileDevice) {
      // Attempt to open the custom URL
      window.location.href = deepLinkUrl;

      // Wait for 2 seconds to check if the app opened
      setTimeout(() => {
        // Check if the user is still on your page after the timeout
        if (!document.hidden) {
          // Redirect to the appropriate app store
          if (/Android/i.test(navigator.userAgent)) {
            window.location.href =
              "https://play.google.com/store/apps/details?id=com.ultrachat.ultrachat&hl=en_US";
          } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            window.location.href =
              "https://apps.apple.com/us/app/famed-chat-ai-icon-chat/id6446922396";
          }
        }

        setIsLoading(false);
      }, 2000); // 2 seconds timeout
    } else {
      // For non-mobile devices, redirect to a fallback URL
      window.location.href = "https://famed.chat/";
    }
  };

  return (
    <main style={{ backgroundColor: "#171631" }}>
      <Helmet defer={true}>
        <title>Open in App</title>
        {/* ... other helmet data as in Landing component */}
      </Helmet>

      {/* ... other shared elements, such as alerts, if needed */}

      <section className="slider-area slider-bg2 second-slider-bg d-flex fix">
        {/* ... stars background and other elements if needed */}
        <div className="container action-space">
          <div className="row">
            <div className="col-lg-12">
              <div className="slider-content second-slider-content text-center">
                {/* ... slogan and subslogan if needed */}
                <h3
                  style={{
                    color: "#ff3494",
                    marginTop: "40px",
                  }}
                >
                  Connect with Me on Ultra.Chat 👇
                </h3>
              </div>
            </div>
          </div>

          {/* The button for opening the app */}
          <div className="row">
            <div className="col-lg-12">
              <div className="slider-content text-center">
                <button onClick={openCustomUrl} className="open-in-app-button">
                  {isLoading ? "Loading..." : "Add Me & Explore My AI Bots"}
                </button>
              </div>
            </div>
          </div>

          {/* If you want to include links to Play Store or App Store from the original component, you can add them here */}
        </div>
      </section>
    </main>
  );
};

export default DeepLinkPage;
