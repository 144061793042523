import React from 'react';
import ReactDOM from 'react-dom/client';
// import Amplify, { Auth, Hub } from 'aws-amplify';
import './index.css';
import Landing from './Landing';
import ChatView from './ChatView';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import awsconfig from './aws-exports';

// Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <App></App>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
